import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { GeneralService } from '../general.service';


import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
interface Item {
    name: string;
    value: number;
    selected: boolean;
}
interface YorN {
    name: string;
    value: number;
    selected: boolean;
}

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss']
})
export class VehicleDetailsComponent {
   
    @ViewChild('card1') card1!: ElementRef;
    @ViewChild('card2') card2!: ElementRef;
    HomeUrl: any;
    isTimelineComplete: boolean = false;
    arr: any;
    dataResult: any;
    Quationdata: any;
    Subquation: any;
    Optionsdata: any;
    currentCategoryIndex = 1;
    Cartypedata: string | null = ''; // Assign default or empty string
    typeID: string | null = '';
    CarModel: string | null = '';
    CarmodelID: string | null = '';
    carModel: string = ''; // Property to bind with Car Model input
    cartype: any;
    carStyle: any;
    Milage: any;
    Suboption: any;
    selectedItem: string = '';
    currentCardIndex: number = 0;
    Colors: any;
    Interiorsdata: any;
    selectedValue: number | null = null;
    items: Item[] = [
        { name: 'Clean', value: 1, selected: false },
        { name: 'Salvage', value: 2, selected: false },
        { name: 'Rebuilt', value: 3, selected: false }
        // Add more items as needed
    ];
    ItemYorN: YorN[] = [
        { name: 'Yes', value: 43, selected: false },
        { name: 'No', value: 44, selected: false },

    ];
    selectedItem2: number | null = null;
    selectedItem1: string | null = null;
    constructor(private renderer: Renderer2, private viewportScroller: ViewportScroller, public router: Router, public generalService: GeneralService, public http: HttpClient) {
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            debugger
            //this.Cartypedata = localStorage.getItem("Cartype");
            this.Cartypedata = localStorage.getItem('Cartype');
            this.typeID = localStorage.getItem('CartypeId');
            this.CarModel = localStorage.getItem('CarModel');
            this.CarmodelID = localStorage.getItem('CarModelID');

            this.cartype = this.Cartypedata;
            this.carStyle = this.CarModel;
        });

    }

    ngOnInit(): void {
        this.GetCategories();
        this.GetQuastions();
        this.GetOptions();
        this.GetOptions1();
        this.GetSubQuations();
        this.GetSubOptions();

    }



    selectOption1(option: string): void {
        debugger
        if (this.selectedItem1 === option) {
            // Deselect the option if it's already selected
            this.selectedItem1 = null;
        } else {
            // Select the clicked option
            this.selectedItem1 = option;
        }
    }

    getChipStyle1(option: string): any {
        return {
            'background-color': this.selectedItem1 === option ? 'green' : '#f0f0f0',
            'color': this.selectedItem1 === option ? 'white' : 'black',
            'border-color': this.selectedItem1 === option ? 'green' : '#ccc'
        };
    }





    toggleSelection1(selectedItem) {
        this.items.forEach(item => {
            if (item === selectedItem) {
                item.selected = !item.selected;
            } else {
                item.selected = false; // Deselect other items
            }
        });
    }
    YorNtoggle(selectedItem) {
        this.ItemYorN.forEach(YorN => {
            if (YorN === selectedItem) {
                YorN.selected = !YorN.selected;
            } else {
                YorN.selected = false; // Deselect other items
            }
        });
    }
    showNextCard1(): void {
        if (this.currentCardIndex < 6 && this.currentCategoryIndex < 6) {
            this.currentCardIndex++;
            this.currentCategoryIndex++;

            // Perform any other logic based on the updated indices if needed
        }
    }
    handleChipClick(value: number): void {
        this.selectedValue = value;
    }
    selectOption(option: string): void {
        debugger
        this.selectedItem = option; // Set the selected option
    }

    // Function to dynamically set chip styles based on selection
    getChipStyle(option: string): any {
        return {
            'background-color': this.selectedItem === option ? 'green' : '#f0f0f0',
            'color': this.selectedItem === option ? 'white' : 'black',
            'border-color': this.selectedItem === option ? 'green' : '#ccc'
        };
    }
    GetCategories() {
        debugger
        this.arr = []
        this.arr.push({
            RegId: '2',
            TokenId: '3A55AF42-D929-4699-A033-644CE99CCAAA'
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/SYCar/SYC_VehicleDetailsSteps_Curd";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.dataResult = data
        }, err => {
        })
    }
    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    GetQuastions() {
        debugger
        this.arr = []
        this.arr.push({
            RegId: '2',
            TokenId: '3A55AF42-D929-4699-A033-644CE99CCAAA'
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/SYCar/SYC_QuastiomMaster_curd";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.Quationdata = data
        }, err => {
        })
    }



    GetOptions() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '3');

        var url = "api/SYCar/GetOptions_basedOn_QId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            //this.Colors = data
            this.Colors = data

        }, err => {
        })
    }


    GetOptions1() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '5');

        var url = "api/SYCar/GetOptions_basedOn_QId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            //this.Colors = data
            this.Interiorsdata = data

        }, err => {
        })
    }

    GetSubOptions() {
        debugger

        var UploadFile = new FormData();
        UploadFile.append("Param", '5');

        var url = "api/SYCar/GetOptions_basedOn_QId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            //this.Colors = data
            this.Suboption = data

        }, err => {
        })
    }

    GetSubQuations() {
        debugger

        this.arr = []
        this.arr.push({
            RegId: '2',
            TokenId: '3A55AF42-D929-4699-A033-644CE99CCAAA'
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/SYCar/SYC_SubQuestion_Curd";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.Subquation = data
        }, err => {
        })
    }

    toggleSelection(item: any): void {
        item.selected = !item.selected;
    }

    checkTimelineCompletion() {

        debugger
        this.isTimelineComplete = this.currentCategoryIndex === this.dataResult.length;
    }

    isCategoryComplete(index: number): boolean {
        debugger
        // Add your logic to determine completion status based on the index or other criteria
        // For example:
        // Assume dataResult is an array containing your categories
        // Here, I'm checking if the category at the given index is complete
        return index < this.currentCategoryIndex;
    }
    moveToNextCategory() {
        debugger
        if (this.currentCategoryIndex < this.dataResult.length + 1) {
            this.currentCategoryIndex++;
            this.checkTimelineCompletion(); // Check if timeline is complete after moving to the next category
        }
    }

    moveToPreviousCategory() {
        if (this.currentCardIndex > 0) {
            this.currentCardIndex--;
            this.currentCategoryIndex--;
        }
    }

    calculateIconPosition() {
        debugger
        const percentPerCategory = 100 / this.dataResult.length;
        return `${this.currentCategoryIndex * percentPerCategory}%`;
    }



    // Example function to move to the next category after a certain delay
    simulateCategoryProgress() {
        for (let i = 0; i < this.dataResult.length; i++) {
            const customDelay = 1000 * (i + 1); // Default delay per category

            // Example: Increase delay for specific indices
            if (i === 2 || i === 6) {
                const increasedDelay = customDelay * 2; // Doubling the delay for certain indices
                setTimeout(() => {
                    this.moveToNextCategory();
                }, increasedDelay);
            } else {
                setTimeout(() => {
                    this.moveToNextCategory();
                }, customDelay);
            }
        }
    }

}
