<nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>

            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/"><b>Home/</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b>About Us</b></li>
            <div>About Us</div>
        </ul>

    </div>

</nav>
<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>About <span>Cars For Cash</span></h2>
            <!--<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
        </div>
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <!--<h2>We Are Digital <span>Marketing & Branding</span></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
                    </div>
                    <!--<ul class="features-list">
                        <li><i class="fas fa-check"></i>Creative Design</li>
                        <li><i class="fas fa-check"></i>Retina Ready</li>
                        <li><i class="fas fa-check"></i>Responsive Design</li>
                        <li><i class="fas fa-check"></i>Modern Design</li>
                        <li><i class="fas fa-check"></i>Awesome Design</li>
                        <li><i class="fas fa-check"></i>Digital Marketing & Branding</li>
                    </ul>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>-->
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="about-video">
                    <img src="../../../../assets/img/Sellyoucarimgs/coming.jpg" />
                    <!--<img src="../../../../assets/img/Sellyoucarimgs/sellurcar.JPG" />-->
                    <!--<img src="assets/img/about.jpg" alt="about">-->
                    <div class="video-btn">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
