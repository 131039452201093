import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { GeneralService } from '../general.service';
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {
    public form: FormGroup;
    arr: any = [];
    passwordshow: boolean = false;
    Mobilenum: any; checkNo: any;
    mobiledata: any;

    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }

    constructor(public generalservice: GeneralService, public fb: FormBuilder, public http: HttpClient,
        private router: Router) {

        this.form = this.fb.group({
            CustmoerName: ['', Validators.required],
            Mobile: ['', Validators.required],
            Email: ['', Validators.required],
            Password: ['', Validators.required],
            Address: ['', Validators.required]

        })

    }

   

    ngOnInit() {

    }

    onSubmit() {
        debugger
        if (
            !this.form.value.CustmoerName ||
            !this.form.value.Mobile ||
            !this.form.value.Email ||
            !this.form.value.Password ||
            !this.form.value.Address
        ) {
            this.generalservice.ShowAlert("ERROR", 'Please fill in all fields', 'error');
            return; 
        }
        
        debugger
        this.arr = [];
        this.arr.push({
            Username: this.form.value.CustmoerName,
            Mobile: this.form.value.Mobile,
            EMail: this.form.value.Email,
            Password: this.form.value.Password,
            Address: this.form.value.Address,
            RoleId: 2,
        });

        var uploadfile = new FormData;
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", "1");

        var url = "api/SYCar/SYC_Reg_User_Curd";
        this.generalservice.PostData(url, uploadfile).then(data => {
            debugger
            if (data === "Mobile Exists") {
                this.generalservice.ShowAlert("Warning", 'Mobile Number Already Exists', 'error');
            } else if (data != "") {
                this.generalservice.ShowAlert("SUCCESS", 'Your Registration Completed Successfully', 'Success');
                this.router.navigate(['/CarTypes']);
            }
        }, err => {
            this.generalservice.ShowAlert("ERROR", 'Please Enter Valid Credentials', 'error');
        });
    }

    /*check mobile exist or not*/
    CheckMobileNo() {
        debugger;
        this.Mobilenum = this.form.get('Mobile').value;
        var UploadFile = new FormData();
        var UploadFile = new FormData();
        UploadFile.append("MobileNo", this.Mobilenum);
        UploadFile.append("Flag", "1");
        var url = "api/SYCar/Get_Check_Mobile_Email"
        debugger;
        this.generalservice.PostData(url, UploadFile).then(data => {
            this.mobiledata = data;
            debugger;
            if (data == 'Mobile Exists') {
                this.checkNo = 10;
            }
            else {
                this.checkNo = 11;
            }
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }
  /*endregion*/


}
