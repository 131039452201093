<section class="how-work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>How it <span>Works</span></h2>
            <p><!--Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.--></p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-info-circle"></i>
                    </div>
                    <h3>Submit Your Car Details</h3>
                    <p>Tell us about your vehicle by providing some basic information. The more details you provide, the more accurate our initial estimate will be.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <!--<i class="fas fa-drafting-compass"></i>-->
                        <i class="fas fa-calendar-plus"></i>
                    </div>
                    <h3>Schedule a Free Inspection</h3>
                    <p>We'll arrange a convenient time to inspect your car. Our expert evaluators will assess its condition and provide a final offer.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <!--<i class="far fa-paper-plane"></i>-->
                        <i class="fas fa-handshake"></i>
                    </div>
                    <h3>Receive Your Offer</h3>
                    <p>
                        Once the inspection is complete, you'll receive a competitive offer. No obligations, no pressure—take your time to decide.
                    </p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <!--<i class="far fa-paper-plane"></i>-->
                        <i class="fas fa-money-check"></i>
                    </div>
                    <h3>Get Paid</h3>
                    <p>
                        Accept our offer, and we'll handle the paperwork. You can choose to be paid via check or direct deposit. It's that simple!
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>
