 
<div class="container">
    <div class="row">
        <!--<div class="col-lg-1"></div>
        <div class="col-lg-2">
            <img src="../../../assets/img/Sellyoucarimgs/sell.avif" />
            <h5></h5>
        </div>
        <div class="col-lg-4"> <h5>2019 Mercedes-Benz C-Class</h5></div>
        <div class="col-lg-3"></div>-->
        <div class="col-lg-12">
            <div class="container">
                <div class="timeline-container" [ngClass]="{'completed-timeline': isTimelineComplete}">

                    <ul class="timeline">
                        <li class="font_size" *ngFor="let item of dataResult; let i = index" [ngClass]="{'completed' : i < currentCategoryIndex,'line-completed': i < currentCategoryIndex - 1,'green-line': i <= currentCategoryIndex - 1 && currentCategoryIndex !== 0               }">
                            {{ item.VDSName }}<i class="fa-solid fa-car-side" *ngIf="i === currentCategoryIndex - 1" style="color:red"></i>
                        </li>
                    </ul>
                </div>
            </div>
        </div>





        <div class="container">
            <!-- First Card -->
            <div class="row" *ngIf="currentCardIndex === 0">
                <!-- Content for the first card -->

                <div class="row">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-9 col-md-6 col-sm-6 col-xs-6">
                        <h4 style="color: black;">{{Quationdata[0].QName}}</h4>
                        <p><b>Model<span style="color:red">*</span></b></p>
                        <input type="text" placeholder="Enter Car Model..." [(ngModel)]="cartype" /><br />
                        <p><b>Style<span style="color:red">*</span></b></p>
                        <input type="text" placeholder="Enter Car Style..." [(ngModel)]="carStyle" /><br />
                        <p><b>Milage<span style="color:red">*</span></b></p>
                        <input type="text" placeholder=" Enter Car Milage.." [(ngModel)]="Milage" />
                    </div>
                </div>
                <!-- ... -->
                <br />
                <div class="row">
                    <div class="col-lg-5 col-md-6 col-sm-6 col-xs-6">
                        <!--<button type="button" class="back" (click)="moveToPreviousCategory()">Back</button>-->

                    </div>
                    <div class="col-lg-3  col-md-6 col-sm-6 col-xs-6">
                        <button type="button" class="next" (click)="showNextCard1()">Next</button>
                    </div>
                     

                </div>
            </div>

            <!-- Second Card -->
            <div class="row" *ngIf="currentCardIndex === 1">

                <div class="row">
                    <div class="col-lg-12">
                        <h5 class="gradient-text">Color & available choices</h5>
                        <h4 style="color: black;">{{Quationdata[1].QName}}</h4>

                    </div>
                    <div class="col-lg-3"></div>
                    <div class="col-lg-9">
                        <div class="row">


                            <div class="col-lg-2 col-md-6 col-sm-6 col-6">
                                <div class="pantone-card" style="background: black;" (click)="selectOption('Black')" [ngClass]="{'selected': selectedItem === 'Black'}">
                                    <div class="color"></div>
                                    <div class="name mt-5">{{Colors[0].OptionName}}</div>
                                </div>

                            </div>
                            <div class="col-lg-2 col-md-6 col-sm-6  col-6">
                                <div class="pantone-card" style="background: rgb(12, 29, 56);" (click)="selectOption('RGB_12_29_56')" [ngClass]="{'selected': selectedItem === 'RGB_12_29_56'}">
                                    <div class="color"></div>
                                    <div class="name mt-5">{{Colors[1].OptionName}}</div>
                                </div>
                            </div>
                            <!-- Add more similar code for other options -->


                            <div class="col-lg-2 col-md-6 col-sm-6  col-6 pt5">
                                <div class="pantone-card" style="background-color: rgb(86, 0, 18)" (click)="selectOption('rgb(86, 0, 18)')" [ngClass]="{'selected': selectedItem === 'rgb(86, 0, 18)'}">
                                    <div class="color"></div>
                                    <div class="name mt-5">{{Colors[2].OptionName}}</div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt5">
                                <div class="pantone-card" style="background-color: rgb(238, 234, 217)" (click)="selectOption('rgb(238, 234, 217)')" [ngClass]="{'selected': selectedItem === 'rgb(238, 234, 217)'}">
                                    <div class="color"></div>
                                    <div class="name mt-5">{{Colors[3].OptionName}}</div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt5">
                                <div class="pantone-card" style="background-color: rgb(12, 25, 20)" (click)="selectOption(' rgb(12, 25, 20)')" [ngClass]="{'selected': selectedItem === ' rgb(12, 25, 20)'}">
                                    <div class="color"></div>
                                    <div class="name mt-5">{{Colors[4].OptionName}}</div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt5">
                                <div class="pantone-card" style="background-color: rgb(201, 205, 206)" (click)="selectOption('rgb(86, 0, 18)')" [ngClass]="{'selected': selectedItem === 'rgb(201, 205, 206)'}">
                                    <div class="color"></div>
                                    <div class="name mt-5">{{Colors[5].OptionName}}</div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div class="pantone-card" style="background-color:rgb(17, 22, 34)" (click)="selectOption('rgb(17, 22, 34)')" [ngClass]="{'selected': selectedItem === 'rgb(17, 22, 34)'}">
                                    <div class="color"></div>
                                    <div class="name mt-5">{{Colors[6].OptionName}}</div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div class="pantone-card" style="background-color:rgb(161, 161, 159)" (click)="selectOption('rgb(161, 161, 159)')" [ngClass]="{'selected': selectedItem === 'rgb(161, 161, 159)'}">
                                    <div class="color"></div>
                                    <div class="name mt-5">{{Colors[7].OptionName}}</div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div class="pantone-card" style="background-color: rgb(14, 14, 14)" (click)="selectOption('rgb(14, 14, 14)')" [ngClass]="{'selected': selectedItem === 'rgb(14, 14, 14)'}">
                                    <div class="color"></div>
                                    <div class="name mt-5">{{Colors[8].OptionName}}</div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div class="pantone-card" style="background-color: rgb(210, 208, 193)" (click)="selectOption('rgb(210, 208, 193)')" [ngClass]="{'selected': selectedItem === 'rgb(210, 208, 193)'}">
                                    <div class="color"></div>
                                    <div class="name mt-5">{{Colors[9].OptionName}}</div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 pt-5 pt5">
                                <div class="pantone-card" style="background-color: rgb(54, 57, 56)" (click)="selectOption('rgb(54, 57, 56)')" [ngClass]="{'selected': selectedItem === 'rgb(54, 57, 56)'}">
                                    <div class="color"></div>
                                    <div class="name  mt-5">{{Colors[10].OptionName}}</div>
                                </div>
                            </div>
                        </div>

                        </div>

                        
                        <div class="col-lg-12 pt-5"><h4 style="color: black;">{{Quationdata[2].QName}}</h4></div>
                        <div class="col-lg-3"></div>
                        <div class="col-lg-9">

                            <!--<h4 style="color: black;">{{Quationdata[2].QName}}</h4>-->
                            <div class="row">

                                <div class="col-lg-2 col-6">
                                    <div class="pantone-card" style="background:rgb(27, 28, 32)" (click)="selectOption('rgb(27, 28, 32)')" [ngClass]="{'selected': selectedItem === 'rgb(27, 28, 32)'}">
                                        <div class="color"></div>
                                        <div class="name  mt-5">{{Colors[0].OptionName}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-6">
                                    <div class="pantone-card" style="background: rgb(174, 8, 31)" (click)="selectOption('rgb(174, 8, 31))')" [ngClass]="{'selected': selectedItem === 'rgb(174, 8, 31)'}">
                                        <div class="color"></div>
                                        <div class="name  mt-5">{{Colors[1].OptionName}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-6 pt5">
                                    <div class="pantone-card" style="background-color: rgb(81, 61, 60)" (click)="selectOption('rgb(81, 61, 60)')" [ngClass]="{'selected': selectedItem === 'rgb(81, 61, 60)'}">
                                        <div class="color"></div>
                                        <div class="name  mt-5">{{Colors[2].OptionName}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-6 pt5">
                                    <div class="pantone-card" style="background-color: rgb(232, 216, 190)" (click)="selectOption('rgb(232, 216, 190)')" [ngClass]="{'selected': selectedItem === 'rgb(232, 216, 190)'}">
                                        <div class="color"></div>
                                        <div class="name  mt-5">{{Colors[3].OptionName}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-6 pt5">
                                    <div class="pantone-card" style="background-color: rgb(27, 28, 32)" (click)="selectOption('rgb(27, 28, 32)')" [ngClass]="{'selected': selectedItem === 'rgb(27, 28, 32)'}">
                                        <div class="color"></div>
                                        <div class="name  mt-5">{{Colors[4].OptionName}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-6 pt5">
                                    <div class="pantone-card" style="background-color: rgb(131, 130, 136)" (click)="selectOption('rgb(131, 130, 136)')" [ngClass]="{'selected': selectedItem === 'rgb(131, 130, 136)'}">
                                        <div class="color"></div>
                                        <div class="name  mt-5">{{Colors[5].OptionName}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-6 pt-5 pt5">
                                    <div class="pantone-card" style="background-color:rgb(81, 61, 60)" (click)="selectOption('rgb(81, 61, 60)')" [ngClass]="{'selected': selectedItem === 'rgb(81, 61, 60)'}">
                                        <div class="color"></div>
                                        <div class="name  mt-5">{{Colors[6].OptionName}}</div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-6 pt-5 pt5">
                                    <div class="pantone-card" style="background-color:rgb(232, 216, 190)" (click)="selectOption('rgb(232, 216, 190)')" [ngClass]="{'selected': selectedItem === 'rgb(232, 216, 190)'}">
                                        <div class="color"></div>
                                        <div class="name  mt-5">{{Colors[7].OptionName}}</div>
                                    </div>
                                </div>



                            </div>
                            <br />
                            <h4 class=" pt-5" style="color: black;">{{Quationdata[3].QName}}</h4>
                            <div class="row">
                                <div class="col-lg-3" *ngFor="let item of Interiorsdata" (click)="toggleSelection(item)" [ngClass]="{'selected': item.selected}">
                                    <div class="chip" [ngStyle]="{'background-color': item.selected ? 'green' : '#f0f0f0', 'color': item.selected ? 'white' : 'black', 'border-color': item.selected ? 'green' : '#ccc'}">{{item.OptionName}}</div>
                                </div>
                            </div>
                        </div>
                        


                    
                </div><br />
                <br />
                <!-- ... -->
                <div class="row pt-3">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-3 ">
                        <button type="button" class="back" (click)="moveToPreviousCategory()">Back</button>

                    </div>
                    <div class="col-lg-3">
                        <button type="button" class="next" (click)="showNextCard1()">Next</button>
                    </div>

                </div>
            </div>

            <!-- Third Card -->
            <div class="row" *ngIf="currentCardIndex === 2">

                <div class="row">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-9">


                        <h5 class="gradient-text" style="margin-left: -345px;">Vehicle history</h5>
                        <h4 style="margin-left: -375px;color: black;">{{Quationdata[4].QName}}</h4>
                        <div class="row">
                            <div class="col-lg-2" *ngFor="let item of items" (click)="toggleSelection1(item)" [ngClass]="{'selected': item.selected}">
                                <div class="chip" [ngStyle]="{'background-color': item.selected ? 'green' : '#f0f0f0', 'color': item.selected ? 'white' : 'black', 'border-color': item.selected ? 'green' : '#ccc'}">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--------------Q5---------------------->


                <div class="row">
                    <h4 style="color: black;text-align: center;">{{Quationdata[5].QName}}</h4>
                </div>
                <div class="row">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-2" (click)="selectOption('Yes')" [ngClass]="{'selected': selectedItem === 'Yes'}">
                        <div class="chip1" [ngStyle]="getChipStyle('Yes')">
                            Yes
                        </div>
                    </div>
                    <div class="col-lg-2" (click)="selectOption('No')" [ngClass]="{'selected': selectedItem === 'No'}">
                        <div class="chip1" [ngStyle]="getChipStyle('No')">
                            NO
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div *ngIf="selectedItem === 'Yes'">
                        <div class="row">
                            <h4 style="color: black; text-align: center;">
                                {{Subquation[0].SQName}}
                            </h4>
                            <div class="col-lg-3"></div>
                            <div class="col-lg-2">
                                <div class="chip1">YES</div>
                            </div>
                            <div class="col-lg-2">
                                <div class="chip1">NO</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedItem === 'No'">
                        <div class="row">
                            <h4 style="color: black;text-align: center;">
                                {{Subquation[1].SQName}}
                            </h4>
                            <div class="col-lg-3"></div>
                            <div class="col-lg-2">
                                <div class="chip1" (click)="selectOption1('Loan')" [ngStyle]="getChipStyle1('Loan')">Loan</div>
                            </div>
                            <div class="col-lg-2">
                                <div class="chip1" (click)="selectOption1('Lease')" [ngStyle]="getChipStyle1('Lease')">Lease</div>
                            </div>
                            <div class="col-lg-2">
                                <div class="chip1" (click)="selectOption1('Lien')" [ngStyle]="getChipStyle1('Lien')">Lien</div>
                            </div>
                            <div style="margin-left: 285px;margin-top: 30px;">
                                <b>Amount Owed</b><br />
                                <input type="text" placeholder="Amount Owed" />
                            </div>
                        </div>
                    </div>




                </div>
                <!-- ... -->
                <div class="row" style="margin-left: 265px;">
                    <div class="col-lg-3">
                        <button type="button" class="back" (click)="moveToPreviousCategory()">Back</button>

                    </div>
                    <div class="col-lg-3">
                        <button type="button" class="next" (click)="showNextCard1()">Next</button>
                    </div>

                </div>
            </div>

            <!-- Fourth Card -->
            <div class="row" *ngIf="currentCardIndex === 3">
                <!-- Content for the fourth card -->
                <h5>Fourth Card Content</h5>
                <div class="row">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-9">
                        <h5>We found your car! Let’s confirm a few details.</h5>
                        <p><b>Model<span style="color:red">*</span></b></p>
                        <input type="text" placeholder="Enter Car Model..." [(ngModel)]="cartype" /><br />
                        <p><b>Style<span style="color:red">*</span></b></p>
                        <input type="text" placeholder="Enter Car Style..." [(ngModel)]="carStyle" /><br />
                        <p><b>Milage<span style="color:red">*</span></b></p>
                        <input type="text" placeholder=" Enter Car Milage.." [(ngModel)]="Milage" />

                    </div>
                </div>
                <!-- ... -->
                <div class="row" style="margin-left: 265px;">
                    <div class="col-lg-3">
                        <button type="button" class="back" (click)="moveToPreviousCategory()">Back</button>

                    </div>
                    <div class="col-lg-3">
                        <button type="button" class="next" (click)="showNextCard1()">Next</button>
                    </div>

                </div>
            </div>

            <!-- Fifth Card -->
            <div class="row" *ngIf="currentCardIndex === 4">

                <!-- Content for the fifth card -->
                <h5>Fifth Card Content</h5>
                <div class="row">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-9">
                        <h5>We found your car! Let’s confirm a few details.</h5>
                        <p><b>Model<span style="color:red">*</span></b></p>
                        <input type="text" placeholder="Enter Car Model..." [(ngModel)]="cartype" /><br />
                        <p><b>Style<span style="color:red">*</span></b></p>
                        <input type="text" placeholder="Enter Car Style..." [(ngModel)]="carStyle" /><br />
                        <p><b>Milage<span style="color:red">*</span></b></p>
                        <input type="text" placeholder=" Enter Car Milage.." [(ngModel)]="Milage" />
                    </div>
                </div>
                <div class="row" style="margin-left: 265px;">
                    <div class="col-lg-3">
                        <button type="button" class="back" (click)="moveToPreviousCategory()">Back</button>

                    </div>
                    <div class="col-lg-3">
                        <button type="button" class="next" (click)="showNextCard1()">Next</button>
                    </div>

                </div>
            </div>
            <!-- Fifth Card -->
            <div class="row" *ngIf="currentCardIndex === 5">

                <!-- Content for the fifth card -->
                <h5>Fifth Card Content</h5>
                <div class="row">
                    <div class="col-lg-3"></div>
                    <div class="col-lg-9">
                        <h5>We found your car! Let’s confirm a few details.</h5>
                        <p><b>Model<span style="color:red">*</span></b></p>
                        <input type="text" placeholder="Enter Car Model..." [(ngModel)]="cartype" /><br />
                        <p><b>Style<span style="color:red">*</span></b></p>
                        <input type="text" placeholder="Enter Car Style..." [(ngModel)]="carStyle" /><br />
                        <p><b>Milage<span style="color:red">*</span></b></p>
                        <input type="text" placeholder=" Enter Car Milage.." [(ngModel)]="Milage" />
                    </div>
                </div>
                <div class="row" style="margin-left: 265px;">
                    <div class="col-lg-3">
                        <button type="button" class="back" (click)="moveToPreviousCategory()">Back</button>

                    </div>
                    <div class="col-lg-3">
                        <button type="button" class="next" (click)="showNextCard1()">Submit</button>
                    </div>

                </div>
            </div>


        </div>

    </div>
</div>
