import { Component, OnInit, HostListener } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { GeneralService } from '../../../general.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    selectedProduct: any;
    urls: any; Customer: any; logindeta123: any;
    ProductName: any;
    isLoggedIn: boolean = false;
    showDropdown: boolean = false; logindeta: any; loginDet1: any; Username: any;
    constructor(private viewportScroller: ViewportScroller, public generalService: GeneralService, private router: Router) {


        debugger
        this.Customer = localStorage.getItem("Customer");
       // this.logindeta123 = JSON.parse(this.loginDet1);
        this.loginDet1 = localStorage.getItem("LoginDetails");
        this.logindeta = JSON.parse(this.loginDet1);


    }
    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }



    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
        this.Customer = localStorage.getItem("Customer");
    }

    navigateToCarInfoPage() {
        this.router.navigate(['/CarInfo']); // Replace '/car-info' with the route you want to navigate to
    }
    info() {
        this.router.navigate(['/CarTypes'])
    } home() {
        this.router.navigate(['/'])
    }about() {
        this.router.navigate(['/about'])
    }contact() {
        this.router.navigate(['/Contact'])
    }
    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    GetSearchurl() {
        debugger
        var uploadfile = new FormData;
        uploadfile.append('Param1', this.selectedProduct);
        const url = "api/YKLabs/Get_allproducts_public_url";
        this.generalService.PostData(url, uploadfile).then((data: any) => {
            this.urls = data;
        }, (err) => {
            console.log('error in getsearchurl', 'data');
        })
    }

    GotoDetails(Val: any) {
        debugger
        if (Val.producturl == "Products") {
            this.router.navigate(['/Products/' + Val.CategoryName, Val.ProductName]);
        }
        else if (Val.producturl == "Cropguidance") {
            this.router.navigate(['/cropguidance', Val.CategoryName, Val.ProductName]).then(() => {
                window.location.reload();
            });
            localStorage.setItem("CropguidelinesId", Val.CategoryName);
            localStorage.setItem("CropName", Val.ProductName);

        }
        else if (Val.producturl == "Blog") {
            debugger
            this.router.navigate(['/Blog', Val.ProductName]).then(() => {
                window.location.reload();
            });
            localStorage.setItem("BlogTitle", Val.ProductName);
        }
    }

    //logout() {
    //    this.isLoggedIn = false;
    //}

    logout() {
        this.isLoggedIn = false;
        this.Username = undefined;

        this.router.navigate(['/login']).then(() => {
            window.location.reload();
        });


        // Navigate to /login and reload after a short delay
        //this.router.navigate(['/login']).then(() => {
        //  //  setTimeout(() => {
        //        window.location.reload();
        //   // }, 100); // Adjust the delay as needed
        //});

        // Clear localStorage
        localStorage.removeItem('LoginDetails');
    }


}
