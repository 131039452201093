import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormBuilder, FormControl, Validators, FormsModule } from '@angular/forms';
import { GeneralService } from '../../../general.service';
import { Route, Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    enquiery: any = [];
    formGroup: any;
    contactForm: FormsModule;
    name: any;
    email: any;
    subject: any;
    number: any;
    message: any;

    constructor(private viewportScroller: ViewportScroller, fb: FormBuilder, public generalservice: GeneralService,
        private router: Router) { }

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {

    }


    onSubmit(form) {
        debugger
        this.enquiery = [];
        this.enquiery.push({
            FarmerName: form.name,
            Mobile: form.number,
            EmailID: form.email,
            Subject: form.subject,
            Message: form.message,
        })
        var uploadfile = new FormData;
        uploadfile.append("Email", JSON.stringify(this.enquiery));
        var url = "api/SYCar/EnquiryMailTo_SYCAdmin";
        this.generalservice.PostData(url, uploadfile).then(data => {
            debugger
            this.generalservice.ShowAlert("SUCCESS", 'Mail sent Successfuly', 'success');
            Swal.fire('SUCCESS',
                'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
                'success')
            this.name = '';
            this.email = '';
            this.subject = '';
            this.number = '';
            this.message = '';
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        });

    }


    submit(form){
        var name = form.name;
        console.log(name);
        
        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);
        
        var subject = form.subject;
        console.log(subject);
        
        var message = form.message;
        console.log(message);
    }

}
