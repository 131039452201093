import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { GeneralService } from '../../general.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { Router, RouterLink } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { retry } from 'rxjs/operators';
interface Item {
    name: string;
    value: number;
    selected: boolean;
}
interface YorN {
    name: string;
    value: number;
    selected: boolean;
}
@Component({
    selector: 'app-vehicle-info',
    templateUrl: './vehicle-info.component.html',
    styleUrls: ['./vehicle-info.component.scss']
})
export class VehicleInfoComponent {
    @ViewChild('card1') card1!: ElementRef;
    @ViewChild('card2') card2!: ElementRef;
    HomeUrl: any;
    isTimelineComplete: boolean = false;
    carin: any = [];
    Username: any;
    VehicleNumber: any;
    isDisabled: boolean = true;
    Mobile: any;
    EMail: any;
    Password: any;
    Address: any;
    arr: any; c: any;
    dataResult: any;
    Quationdata: any;
    Subquation: any;
    Optionsdata: any;
    currentCategoryIndex = 1;
    Cartypedata: string | null = ''; // Assign default or empty string
    typeID: string | null = '';
    CarModel: string | null = '';
    CarmodelID: string | null = '';
    carModel: string = ''; // Property to bind with Car Model input
    cartype: any;
    carStyle: any;
    Milage: any;
    Suboption: any;
    selectedItem: string = '';
    selectedItem143: string = '';
    leakpenel: string = '';
    leakpenel1: string = '';
    wel: string = '';
    Acc: string = '';
    Acc1: string = '';

    carpenal: string = '';
    Ex1: string = '';
    info1: string = '';
    bpenel: string = '';
    Q: string = '';

    selectedex: string = '';
    currentCardIndex: number = 0;
    currentCategoryIndex1: number = 1;
    Colors: any;
    Interiorsdata: any;
    Suboptiondamage: any;
    selectedValue: number | null = null;
    items: Item[] = [
        { name: 'Clean', value: 40, selected: false },
        { name: 'Salvage', value: 41, selected: false },
        { name: 'Rebuilt', value: 42, selected: false }
        // Add more items as needed
    ];
    ItemYorN: YorN[] = [
        { name: 'Yes', value: 43, selected: false },
        { name: 'No', value: 44, selected: false },

    ];
    sms: string | null = null;
    selectedpanel: string | null = null;
    selectedItem1: string | null = null;
    selectedAcc: string | null = null;
    penel: string = '';
    Ins: string = '';
    Inn: string = '';
    mcn: string = '';
    Inn1: string = '';
    info: string = '';
    iconNames: string[] = ['fa-car', 'fa-bicycle', 'fa-bus', 'fa-truck', 'fa-motorcycle'];

    sms1: string;
    In1: string;
    mcdata: any;
    mccon: any;
    leakdata: any;
    leakdata1: any;
    mcdata1 = [];
    Addoptionsdata: any;
    wheelsdata: any;
    tairsdata: any;
    tair1: string = '';
    mccon1: any;
    mccon2: any;
    SQ1: string;
    bodydamage: any;
    logindeta: any;
    SSComment: any;
    logdata: any;
    Cinfo: Storage;
    carDetailsSubmitted = false;
    sendmail: any = []; MailDetails: any;
    loginDet1: any; logindeta123: any;
    localdata: any;
    localreg: any;
    RegId: any;
    ExteriorImages: any;
    InteriorImage: any;
    DocumentImage: any;
    ImageArray: any = [];
    urls: string[] = []; // Array to store uploaded image URLs
    Exteriorimg: string[] = [];
    Interiorimg: string[] = [];
    Documentimg: string[] = [];
    image: any;
    ImageData: any;
    image1: any;
    ImageData1: any;
    image2: any;
    ImageData2: any;
    EImage: any = [];
    IImage: any = []; DImage: any = [];
    maxImages: number = 4;
    maxImages1: number = 2;
    Conformpassword: any;
    passwordshow: boolean = false;
    users: any;
    users1: any; selectedOptionsString: any = [];
    mobiledata: any; checkNo: any; intColors: any; errorMessage: any;
    signInCompleted: boolean = false; 

    selectedItems: string = ''; selectedAccident1: any;


    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }
    constructor(private renderer: Renderer2, private viewportScroller: ViewportScroller,
        public router: Router, public generalService: GeneralService, public http: HttpClient) {



        this.Cartypedata = localStorage.getItem('Cartype');
        this.typeID = localStorage.getItem('CartypeId');
        this.CarModel = localStorage.getItem('CarModel');
        this.RegId = localStorage.getItem('RegId');
        this.loginDet1 = localStorage.getItem("LoginDetails");
        this.logindeta123 = JSON.parse(this.loginDet1);

        //   this.users1 = this.loginDet1[0].Username;
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;

            //this.Cartypedata = localStorage.getItem("Cartype");
            //this.logindeta = localStorage.getItem('Logindetals');



            this.CarmodelID = localStorage.getItem('CarModelID');
            this.VehicleNumber = localStorage.getItem('VehicleNumber');
            this.Milage = localStorage.getItem('Milage');
            this.Username = localStorage.getItem('Username');
            this.users = this.Username;
            this.Mobile = localStorage.getItem('Mobile');

            this.cartype = this.Cartypedata;
            this.carStyle = this.CarModel;
            if (localStorage.LoginDetails) {
                this.logindeta = JSON.parse(localStorage.LoginDetails)
            }


        });

    }

    ngOnInit(): void {
        this.GetCategories();
        this.GetQuastions();
        this.GetOptions();
        this.GetintOptions();
        this.GetOptionsai();
        this.GetOptions1();
        this.GetSubQuations();
        this.GetSubOptions();
        this.GetSubOptions1();
        this.Getmc();
        this.Getmc1();
        this.getleaks();
        this.getleaks1();
        this.Getairs();
        this.Getwheelsoptions();
        this.Getbodypanel();
        const storedIndex = localStorage.getItem('currentCardIndex');

        // If the stored value exists and is a valid number, assign it to currentCardIndex
        if (storedIndex !== null && !isNaN(+storedIndex)) {
            this.currentCardIndex = +storedIndex;
            this.currentCategoryIndex = this.currentCardIndex;
            this.setAndRemoveLocalStorage();

        }
    }
    // Function to set the 'currentCardIndex' in localStorage and remove it after 80 seconds


    setAndRemoveLocalStorage(): void {
        const maxIndex = 7; // Assuming the maximum index is 7
        const currentCardIndex = Number(localStorage.getItem('currentCardIndex'));

        if (!isNaN(currentCardIndex) && currentCardIndex < maxIndex) {
            localStorage.setItem('currentCardIndex', currentCardIndex.toString());

            // Set a timeout to remove 'currentCardIndex' from localStorage after 80 seconds
            setTimeout(() => {
                localStorage.removeItem('currentCardIndex');
            }, 2000); // 2 seconds in milliseconds (2000 milliseconds)
        }
    }

    selectOption1(option: string): void {

        if (this.selectedItem1 === option) {
            localStorage.setItem("SQ1", this.selectedItem1);
            // Deselect the option if it's already selected
            this.selectedItem1 = null;

        } else {
            // Select the clicked option
            this.selectedItem1 = option;
        }
    }


    areAllFieldsEntered(): boolean {

        return this.cartype !== '' && this.carStyle !== '' && this.VehicleNumber !== undefined && this.Milage !== null && this.Milage !== '';
    }

    Sq1(option: string): void {

        if (this.SQ1 === option) {
            // Deselect the option if it's already selected
            this.SQ1 = null;
            localStorage.setItem("SQ2", this.SQ1);

        } else {
            // Select the clicked option
            this.SQ1 = option;
        }
    }
    LLL(option: string): any {
        return {
            'background-color': this.SQ1 === option ? 'green' : '#f0f0f0',
            'color': this.SQ1 === option ? 'white' : 'black',
            'border-color': this.SQ1 === option ? 'green' : '#ccc'
        };
    }
    Q4(option: string): void {
        localStorage.setItem("Q5", option);

        if (this.Q === option) {
            // Deselect the option if it's already selected
            this.Q = null;

        } else {
            // Select the clicked option
            this.Q = option;
        }
    }
    Qua(option: string): any {
        return {
            'background-color': this.Q === option ? 'green' : '#f0f0f0',
            'color': this.Q === option ? 'white' : 'black',
            'border-color': this.Q === option ? 'green' : '#ccc'
        };
    }
    selectaccident(option: string): void {
        localStorage.setItem("Q8", option);

        this.selectedAcc = option; // Set the selected option
    }
    selectaccident1(val: string): void {

        localStorage.setItem("SQ3", val);
        if (this.Acc === val) {
            // Deselect the option if it's already selected
            this.Acc = null;
        } else {
            // Select the clicked option
            this.Acc = val;
        }
    }
    selectaccident11(val: string): void {

        if (this.Acc1 === val) {
            // Deselect the option if it's already selected
            this.Acc1 = null;
        } else {
            // Select the clicked option
            this.Acc1 = val;
        }
    }
    getAccStyle12(option: string): any {
        return {
            'background-color': this.Acc1 === option ? 'green' : '#f0f0f0',
            'color': this.Acc1 === option ? 'white' : 'black',
            'border-color': this.Acc1 === option ? 'green' : '#ccc'
        };
    }
    getAccStyle2(option: string): any {
        return {
            'background-color': this.Acc === option ? 'green' : '#f0f0f0',
            'color': this.Acc === option ? 'white' : 'black',
            'border-color': this.Acc === option ? 'green' : '#ccc'
        };
    }

    getChipStyle1(option: string): any {
        return {
            'background-color': this.selectedItem1 === option ? 'green' : '#f0f0f0',
            'color': this.selectedItem1 === option ? 'white' : 'black',
            'border-color': this.selectedItem1 === option ? 'green' : '#ccc'
        };
    }

    getAccStyle1(option: string): any {
        return {
            'background-color': this.selectedAcc === option ? 'green' : '#f0f0f0',
            'color': this.selectedAcc === option ? 'white' : 'black',
            'border-color': this.selectedAcc === option ? 'green' : '#ccc'
        };
    }



    toggleSelection1(selectedItem) {

        localStorage.setItem("Q4", selectedItem.value);
        this.items.forEach(item => {
            if (item === selectedItem) {
                item.selected = !item.selected;
            } else {
                item.selected = false; // Deselect other items
            }
        });
    }
    YorNtoggle(selectedItem) {
        this.ItemYorN.forEach(YorN => {
            if (YorN === selectedItem) {
                YorN.selected = !YorN.selected;
            } else {
                YorN.selected = false; // Deselect other items
            }
        });
    }
    //showNextCard1(): void {
    //    if (this.currentCardIndex < 6 && this.currentCategoryIndex < 6) {
    //        this.currentCardIndex++;
    //        this.currentCategoryIndex++;

    //        // Perform any other logic based on the updated indices if needed
    //    }
    //}

    showNextCard1(): void {
        debugger
        // Check if any option is selected
        //if (!this.selectedItem) {
        //    alert("Please select an option for all questions.");
        //    return;
        //}

        console.log('Before Increment: ', this.currentCardIndex, this.currentCategoryIndex);

        if (this.currentCardIndex < 7 && this.currentCategoryIndex < 7) {
            this.currentCardIndex++;
            this.currentCategoryIndex++;

            // Store the updated value in localStorage
            localStorage.setItem('currentCardIndex', this.currentCardIndex.toString());

            // Perform any other logic based on the updated indices if needed
        }

        console.log('After Increment: ', this.currentCardIndex, this.currentCategoryIndex);
    }




    showNextCard112(): void {
        debugger
        if (this.selectedItem === null) {
            // Do not proceed if no option is selected
            return;
        }
        console.log('Before Increment: ', this.currentCardIndex, this.currentCategoryIndex);

        if (this.currentCardIndex < 7 && this.currentCategoryIndex < 7) {
            this.currentCardIndex++;
            this.currentCategoryIndex++;

            // Store the updated value in localStorage
            localStorage.setItem('currentCardIndex', this.currentCardIndex.toString());

            // Perform any other logic based on the updated indices if needed
        }

        console.log('After Increment: ', this.currentCardIndex, this.currentCategoryIndex);
    }

    handleChipClick(value: number): void {
        this.selectedValue = value;
    }
    selectOption(option: string): void {

        this.selectedItem = option; // Set the selected option
        localStorage.setItem("Q1", this.selectedItem);
        //  localStorage.setItem("Q2", this.selectedItem,);
    }


    selectingoptions(option: string): void {

        this.selectedItem143 = option; // Set the selected option

        localStorage.setItem("Q2", this.selectedItem143);
    }
    selectexterior(option: string): void {
        this.selectedex = option; // Set the selectexterior option
    }

    // Function to dynamically set chip styles based on selection
    getChipStyle(option: string): any {
        return {
            'background-color': this.selectedItem === option ? 'green' : '#f0f0f0',
            'color': this.selectedItem === option ? 'white' : 'black',
            'border-color': this.selectedItem === option ? 'green' : '#ccc'
        };
    }
    GetCategories() {

        this.arr = []
        this.arr.push({
            RegId: '2',
            TokenId: '3A55AF42-D929-4699-A033-644CE99CCAAA'
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/SYCar/SYC_VehicleDetailsSteps_Curd";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.dataResult = data
        }, err => {
        })
    }
    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    GetQuastions() {

        this.arr = []
        this.arr.push({
            RegId: '2',
            TokenId: '3A55AF42-D929-4699-A033-644CE99CCAAA'
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/SYCar/SYC_QuastiomMaster_curd";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.Quationdata = data
        }, err => {
        })
    }



    GetOptions() {

        var UploadFile = new FormData();
        UploadFile.append("Param", '3');

        var url = "api/SYCar/GetOptions_basedOn_QId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            //this.Colors = data
            this.Colors = data

        }, err => {
        })
    }
    GetintOptions() {

        var UploadFile = new FormData();
        UploadFile.append("Param", '4');

        var url = "api/SYCar/GetOptions_basedOn_QId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            //this.Colors = data
            this.intColors = data

        }, err => {
        })
    }
    GetOptionsai() {


        var UploadFile = new FormData();
        UploadFile.append("Param", '25');

        var url = "api/SYCar/GetOptions_basedOn_QId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            //this.Colors = data
            this.Addoptionsdata = data

        }, err => {
        })
    }


    GetOptions1() {

        var UploadFile = new FormData();
        UploadFile.append("Param", '5');

        var url = "api/SYCar/GetOptions_basedOn_QId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            //this.Colors = data
            this.Interiorsdata = data

        }, err => {
        })
    }

    GetSubOptions() {

        var UploadFile = new FormData();
        UploadFile.append("Param", '5');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.Suboption = data
        }, err => {
        })
    }

    GetSubOptions1() {

        var UploadFile = new FormData();
        UploadFile.append("Param", '6');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.Suboptiondamage = data
        }, err => {
        })
    }
    Getbodypanel() {

        var UploadFile = new FormData();
        UploadFile.append("Param", '6');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.bodydamage = data
        }, err => {
        })
    }
    GetSubQuations() {

        this.arr = []
        this.arr.push({
            RegId: '2',
            TokenId: '3A55AF42-D929-4699-A033-644CE99CCAAA'
        });
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/SYCar/SYC_SubQuestion_Curd";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.Subquation = data
        }, err => {
        })
    }

    //toggleSelection(item: any): void {

    //    item.selected = !item.selected;
    //    localStorage.setItem("Q3", item.OptionId);

    //}

    toggleSelection33(item): void {
        item.selected = !item.selected;

        let selectedOptions = JSON.parse(localStorage.getItem('selectedOptions')) || [];
        if (item.selected) {
            selectedOptions.push(item.OptionName);
        } else {
            selectedOptions = selectedOptions.filter(option => option !== item.OptionName);
        }
        localStorage.setItem('selectedOptions', JSON.stringify(selectedOptions));
    }


    //toggleSelection33(item) {
    //    item.selected = !item.selected; // Toggle selection state

    //    // Store selected options in localStorage if needed
    //    let selectedOptions = JSON.parse(localStorage.getItem('selectedOptions')) || [];
    //    if (item.selected) {
    //        selectedOptions.push(item.OptionName); // Add to selected options
    //    } else {
    //        // Remove from selected options if already selected
    //        selectedOptions = selectedOptions.filter(option => option !== item.OptionName);
    //    }
    //    localStorage.setItem('selectedOptions', JSON.stringify(selectedOptions));
    //}

    isAnyItemSelected(): boolean {
        // Check if any item is selected from Interiorsdata
        return this.Interiorsdata.some(item => !!item.selected);
    }

    Q12(item: any): void {
        item.selected = !item.selected;
        localStorage.setItem("Q12", item.SOId);

    }
    checkTimelineCompletion() {

        this.isTimelineComplete = this.currentCategoryIndex === this.dataResult.length;
    }

    isCategoryComplete(index: number): boolean {
        // Add your logic to determine completion status based on the index or other criteria
        // For example:
        // Assume dataResult is an array containing your categories
        // Here, I'm checking if the category at the given index is complete
        return index < this.currentCategoryIndex;
    }
    moveToNextCategory() {

        if (this.currentCategoryIndex < this.dataResult.length + 1) {
            this.currentCategoryIndex++;
            this.checkTimelineCompletion(); // Check if timeline is complete after moving to the next category
        }
    }

    moveToPreviousCategory() {
        if (this.currentCardIndex > 0) {
            this.currentCardIndex--;
            this.currentCategoryIndex--;
        }
    }

    calculateIconPosition() {
        const percentPerCategory = 100 / this.dataResult.length;
        return `${this.currentCategoryIndex * percentPerCategory}%`;
    }



    // Example function to move to the next category after a certain delay
    simulateCategoryProgress() {
        for (let i = 0; i < this.dataResult.length; i++) {
            const customDelay = 1000 * (i + 1); // Default delay per category

            // Example: Increase delay for specific indices
            if (i === 2 || i === 6) {
                const increasedDelay = customDelay * 2; // Doubling the delay for certain indices
                setTimeout(() => {
                    this.moveToNextCategory();
                }, increasedDelay);
            } else {
                setTimeout(() => {
                    this.moveToNextCategory();
                }, customDelay);
            }
        }
    }
    selectpanel1(val: string): void {
        // localStorage.setItem("SQ4", val,);
        if (this.penel === val) {
            // Deselect the option if it's already selected
            this.penel = null;
        } else {
            // Select the clicked option
            this.penel = val;
        }
    } getpanel1(option: string): any {
        return {
            'background-color': this.penel === option ? 'green' : '#f0f0f0',
            'color': this.penel === option ? 'white' : 'black',
            'border-color': this.penel === option ? 'green' : '#ccc'
        };
    }


    selectpanel(val: string): void {
        localStorage.setItem("SQ4", val);
        this.selectedpanel = val; // Set the selected option
    }


    getpanel(option: string): any {
        return {
            'background-color': this.selectedpanel === option ? 'green' : '#f0f0f0',
            'color': this.selectedpanel === option ? 'white' : 'black',
            'border-color': this.selectedpanel === option ? 'green' : '#ccc'
        };
    }

    cardamage(option: string): void {
        this.carpenal = option; // Set the selected option
        localStorage.setItem("Q9", option);

    }


    cardamage1(option: string): any {
        return {
            'background-color': this.carpenal === option ? 'green' : '#f0f0f0',
            'color': this.carpenal === option ? 'white' : 'black',
            'border-color': this.carpenal === option ? 'green' : '#ccc'
        };
    }


    Exterior(option: string): void {
        this.Ex1 = option; // Set the selected option
        localStorage.setItem("Q10", option);
    }


    Ex(option: string): any {
        return {
            'background-color': this.Ex1 === option ? 'green' : '#f0f0f0',
            'color': this.Ex1 === option ? 'white' : 'black',
            'border-color': this.Ex1 === option ? 'green' : '#ccc'
        };
    }
    bodypanel(option: string): void {
        this.bpenel = option; // Set the selected option
        localStorage.setItem("Q11", option);
    }


    body(option: string): any {
        return {
            'background-color': this.bpenel === option ? 'green' : '#f0f0f0',
            'color': this.bpenel === option ? 'white' : 'black',
            'border-color': this.bpenel === option ? 'green' : '#ccc'
        };
    }
    smd(option: string): void {
        this.sms = option; // Set the selected option
        localStorage.setItem("Q12", option);
    }


    smb(option: string): any {
        return {
            'background-color': this.sms === option ? 'green' : '#f0f0f0',
            'color': this.sms === option ? 'white' : 'black',
            'border-color': this.sms === option ? 'green' : '#ccc'
        };
    }
    smd2(option: string): void {
        this.sms1 = option; // Set the selected option
        localStorage.setItem("Q12", option);
    }


    smb2(option: string): any {
        return {
            'background-color': this.sms1 === option ? 'green' : '#f0f0f0',
            'color': this.sms1 === option ? 'white' : 'black',
            'border-color': this.sms1 === option ? 'green' : '#ccc'
        };
    }


    Interior(option: string): void {
        this.In1 = option; // Set the selected option
        localStorage.setItem("Q16", option);
    }


    In(option: string): any {
        return {
            'background-color': this.In1 === option ? 'green' : '#f0f0f0',
            'color': this.In1 === option ? 'white' : 'black',
            'border-color': this.In1 === option ? 'green' : '#ccc'
        };
    }


    Interior1(option: string): void {
        this.Ins = option; // Set the selected option
        localStorage.setItem("Q17", option);
    }


    In12(option: string): any {
        return {
            'background-color': this.Ins === option ? 'green' : '#f0f0f0',
            'color': this.Ins === option ? 'white' : 'black',
            'border-color': this.Ins === option ? 'green' : '#ccc'
        };
    }
    inc(option: string): void {
        this.Inn = option; // Set the selected option
        localStorage.setItem("Q18", option);
    }


    Inc1(option: string): any {
        return {
            'background-color': this.Inn === option ? 'green' : '#f0f0f0',
            'color': this.Inn === option ? 'white' : 'black',
            'border-color': this.Inn === option ? 'green' : '#ccc'
        };
    }

    inq(option: string): void {
        this.Inn1 = option; // Set the selected option
    }


    Inc11(option: string): any {
        return {
            'background-color': this.Inn1 === option ? 'green' : '#f0f0f0',
            'color': this.Inn1 === option ? 'white' : 'black',
            'border-color': this.Inn1 === option ? 'green' : '#ccc'
        };
    }

    mc(option: string): void {
        this.mcn = option; // Set the selected option
        localStorage.setItem("Q19", option);
    }


    mn(option: string): any {
        return {
            'background-color': this.mcn === option ? 'green' : '#f0f0f0',
            'color': this.mcn === option ? 'white' : 'black',
            'border-color': this.mcn === option ? 'green' : '#ccc'
        };
    }
    Getmc() {

        var UploadFile = new FormData();
        UploadFile.append("Param", '9');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.mcdata = data
        }, err => {
        })
    }
    Getmc1() {

        var UploadFile = new FormData();
        UploadFile.append("Param", '10');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.mcdata1 = data
        }, err => {
        })
    }
    getleaks() {

        var UploadFile = new FormData();
        UploadFile.append("Param", '13');
        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.leakdata = data
        }, err => {
        })
    }
    getleaks1() {

        var UploadFile = new FormData();
        UploadFile.append("Param", '14');
        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.leakdata1 = data
        }, err => {
        })
    }
    toggleSelection12(item: any) {
        // Toggle the selected property of the clicked item
        item.selected = !item.selected;

        this.mccon = item.selected ? item : null; // Set selectedItem based on the selection
    }
    // single option inserting
    //SQ3(item: any) {

    //    // Toggle the selected property of the clicked item
    //    localStorage.setItem("SQ5", item.SOId);
    //    item.selected = !item.selected;

    //    // Set selectedItem based on the selection
    //}

      //SQ4(item1: any) {

    //    // Toggle the selected property of the clicked item
    //    localStorage.setItem("SQ6", item1.SOId);
    //    item1.selected = !item1.selected;

    //    // Set selectedItem based on the selection
    //}

    //SQ9(item: any) {
    //    // Toggle the selected property of the clicked item
    //    item.selected = !item.selected;
    //    localStorage.setItem("SQ5", item.SOId);

    //}

    //SQ10(item: any) {
    //    // Toggle the selected property of the clicked item
    //    item.selected = !item.selected;
    //    localStorage.setItem("SQ5", item.SOId);


    //}

    //SQ13(item: any) {
    //    // Toggle the selected property of the clicked item
    //    item.selected = !item.selected;
    //    localStorage.setItem("SQ13", item.SOId);


    //}
    //SQ14(item: any) {
    //    // Toggle the selected property of the clicked item
    //    item.selected = !item.selected;
    //    localStorage.setItem("SQ14", item.SOId);

    //    // Set selectedItem based on the selection
    //}

    //SQ15(item: any) {
    //    // Toggle the selected property of the clicked item
    //    item.selected = !item.selected;
    //    localStorage.setItem("SQ15", item.SOId);

    //    // Set selectedItem based on the selection
    //}

    //SQ16(item: any) {
    //    // Toggle the selected property of the clicked item
    //    item.selected = !item.selected;
    //    localStorage.setItem("SQ16", item.SOId); // Set selectedItem based on the selection
    //}

    //  multiple option inserting
    SQ3(item: any) {
        // Toggle the selected property of the clicked item
        item.selected = !item.selected;
        // If the item is selected, store its SOId in localStorage with a unique key
        if (item.selected) {
            // Generate a unique key based on the current timestamp
            const timestamp = new Date().getTime();
            const key = `SQ3_${timestamp}`;
            // Store the item's SOId in localStorage using the unique key
            localStorage.setItem(key, item.SOId.toString());
        }

        // If the item is unselected, remove its corresponding entry from localStorage
        else {
            // Iterate through all keys in localStorage
            for (const key in localStorage) {
                if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
                    // Check if the key starts with 'SQ4' and matches the item's SOId
                    if (key.startsWith('SQ3') && localStorage.getItem(key) === item.SOId.toString()) {
                        // Remove the entry from localStorage
                        localStorage.removeItem(key);
                    }
                }
            }
        }

        // Set selectedItem based on the selection
    }

    SQ4(item1: any) {
        item1.selected = !item1.selected;
        if (item1.selected) {
            const timestamp = new Date().getTime();
            const key = `SQ4_${timestamp}`;

            localStorage.setItem(key, item1.SOId.toString());
        }
        else {
            for (const key in localStorage) {
                if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
                    if (key.startsWith('SQ4') && localStorage.getItem(key) === item1.SOId.toString()) {
                        localStorage.removeItem(key);
                    }
                }
            }
        }

    }

    SQ9(item: any) {
        item.selected = !item.selected;
        if (item.selected) {
            const timestamp = new Date().getTime();
            const key = `SQ9_${timestamp}`;

            localStorage.setItem(key, item.SOId.toString());
        }
        else {
            for (const key in localStorage) {
                if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
                    if (key.startsWith('SQ9') && localStorage.getItem(key) === item.SOId.toString()) {
                        localStorage.removeItem(key);
                    }
                }
            }
        }

    }
  
    SQ10(item: any) {
        item.selected = !item.selected;
        if (item.selected) {
            const timestamp = new Date().getTime();
            const key = `SQ10_${timestamp}`;

            localStorage.setItem(key, item.SOId.toString());
        }
        else {
            for (const key in localStorage) {
                if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
                    if (key.startsWith('SQ10') && localStorage.getItem(key) === item.SOId.toString()) {
                        localStorage.removeItem(key);
                    }
                }
            }
        }

    }

    SQ13(item: any) {
        item.selected = !item.selected;
        if (item.selected) {
            const timestamp = new Date().getTime();
            const key = `SQ13_${timestamp}`;

            localStorage.setItem(key, item.SOId.toString());
        }
        else {
            for (const key in localStorage) {
                if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
                    if (key.startsWith('SQ13') && localStorage.getItem(key) === item.SOId.toString()) {
                        localStorage.removeItem(key);
                    }
                }
            }
        }

    }

    SQ14(item: any) {
        item.selected = !item.selected;
        if (item.selected) {
            const timestamp = new Date().getTime();
            const key = `SQ14_${timestamp}`;

            localStorage.setItem(key, item.SOId.toString());
        }
        else {
            for (const key in localStorage) {
                if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
                    if (key.startsWith('SQ14') && localStorage.getItem(key) === item.SOId.toString()) {
                        localStorage.removeItem(key);
                    }
                }
            }
        }

    }
  
    SQ15(item: any) {
        item.selected = !item.selected;
        if (item.selected) {
            const timestamp = new Date().getTime();
            const key = `SQ15_${timestamp}`;

            localStorage.setItem(key, item.SOId.toString());
        }
        else {
            for (const key in localStorage) {
                if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
                    if (key.startsWith('SQ15') && localStorage.getItem(key) === item.SOId.toString()) {
                        localStorage.removeItem(key);
                    }
                }
            }
        }

    }

    SQ16(item: any) {
        item.selected = !item.selected;
        if (item.selected) {
            const timestamp = new Date().getTime();
            const key = `SQ16_${timestamp}`;

            localStorage.setItem(key, item.SOId.toString());
        }
        else {
            for (const key in localStorage) {
                if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
                    if (key.startsWith('SQ16') && localStorage.getItem(key) === item.SOId.toString()) {
                        localStorage.removeItem(key);
                    }
                }
            }
        }

    }
    

    fluiedleaks(option: string): void {
        this.leakpenel = option; // Set the selected option
        localStorage.setItem("Q21", option);
    }


    leaks(option: string): any {
        return {
            'background-color': this.leakpenel === option ? 'green' : '#f0f0f0',
            'color': this.leakpenel === option ? 'white' : 'black',
            'border-color': this.leakpenel === option ? 'green' : '#ccc'
        };
    }
    fluiedleaks1(option: string): void {
        this.leakpenel1 = option; // Set the selected option
        localStorage.setItem("Q22", option);
    }


    leaks1(option: string): any {
        return {
            'background-color': this.leakpenel1 === option ? 'green' : '#f0f0f0',
            'color': this.leakpenel1 === option ? 'white' : 'black',
            'border-color': this.leakpenel1 === option ? 'green' : '#ccc'
        };
    }


    AddIfo(option: string): void {
        this.info = option; // Set the selected option
        localStorage.setItem("Q23", option);
    }


    adin(option: string): any {
        return {
            'background-color': this.info === option ? 'green' : '#f0f0f0',
            'color': this.info === option ? 'white' : 'black',
            'border-color': this.info === option ? 'green' : '#ccc'
        };
    }

    Q23(option: string): void {
        this.info1 = option; // Set the selected option
        localStorage.setItem("Q24", option);
    }


    q23(option: string): any {
        return {
            'background-color': this.info1 === option ? 'green' : '#f0f0f0',
            'color': this.info1 === option ? 'white' : 'black',
            'border-color': this.info1 === option ? 'green' : '#ccc'
        };
    }
    Q25(item: any) {
        // Toggle the selected property of the clicked item
        item.selected = !item.selected;
        localStorage.setItem("Q25", item.OptionId);

        // Set selectedItem based on the selection
    }
    Getwheelsoptions() {

        var UploadFile = new FormData();
        UploadFile.append("Param", '15');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.wheelsdata = data
        }, err => {
        })
    }

    Getairs() {

        var UploadFile = new FormData();
        UploadFile.append("Param", '16');

        var url = "api/SYCar/GetSubOptions_basedOn_SQId";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.tairsdata = data
        }, err => {
        })
    }

    wheel(option: string): void {
        this.wel = option; // Set the selected option
        localStorage.setItem("Q26", option);
    }


    wel1(option: string): any {
        return {
            'background-color': this.wel === option ? 'green' : '#f0f0f0',
            'color': this.wel === option ? 'white' : 'black',
            'border-color': this.wel === option ? 'green' : '#ccc'
        };
    }

    tair(option: string): void {
        this.tair1 = option; // Set the selected option
        localStorage.setItem("Q27", option);
    }


    tai1(option: string): any {
        return {
            'background-color': this.tair1 === option ? 'green' : '#f0f0f0',
            'color': this.tair1 === option ? 'white' : 'black',
            'border-color': this.tair1 === option ? 'green' : '#ccc'
        };
    }


    Regadd() {
        this.arr = []
        this.arr.push({
            RoleId: 2,
            Username: this.Username,
            Mobile: this.Mobile,
            EMail: this.EMail,
            Password: this.Password,
            Address: this.Address,

        });
        localStorage.setItem("Customer", this.Username);
        localStorage.setItem("mobile", this.Mobile);
        localStorage.setItem("Email", this.EMail);
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '1');
        var url = "api/SYCar/SYC_Reg_User_Curd";
        this.generalService.PostData(url, UploadFile).then(data => {

            if (data != null) {

                if (data && data) {
                    this.logdata = data
                    this.logindeta = data;
                    if (data === "Mobile Exists") {
                        this.generalService.ShowAlert("Warning", 'Mobile Number Already Exists', 'error');
                    }
                    else if (data != "") {
                        this.generalService.ShowAlert('SUCCESS', 'Registered successfully Please submit the car details.', 'success');
                        localStorage.setItem("LoginDetails", this.logdata);
                        localStorage.setItem("reg", this.logdata);
                        localStorage.setItem("UserName123", this.logdata);
                        //  this.reloadPageWithDelay();
                        this.signInCompleted = true;
                    }
                }
                else {
                    this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error');
                }
            }
        });
    }


    //reloadPageWithDelay(): void {
    //    setTimeout(() => {
    //        window.location.reload();
    //    }, 2000); // 3000 milliseconds = 3 seconds
    //}

    Submitdetails() {

        this.carin = []; // Clear carin array to start fresh
        const selectedOptions = JSON.parse(localStorage.getItem('selectedOptions')) || [];
        const selectedOptionsString = selectedOptions.join(',');
        const storedImages = JSON.parse(localStorage.getItem('storedImages')) || [];
        const localStorageData = localStorage;
        for (const key in localStorageData) {
            if (Object.prototype.hasOwnProperty.call(localStorageData, key)) {
                const value = localStorageData[key];
                if (key.startsWith('Q')) {
                    let reg = ''
                    if (this.logindeta123 && Array.isArray(this.logindeta123) && this.logindeta123.length > 0) {
                        reg = this.logindeta123[0].RegId;
                    }
                    else {
                        reg = this.logindeta123
                    }
                    this.carin.push({
                        RegId: reg ? reg : localStorage.reg,
                        VehicleTypeId: this.typeID,
                        VehicleBrandId: this.CarmodelID,
                        VehicleNo: this.VehicleNumber,
                        Milage: this.Milage,
                        moption: selectedOptionsString,
                        //   ExteriorImages: storedImages,
                        OptionId: value, // Using the value associated with the key for Q types
                        SSComment: this.SSComment,
                        // Status: true
                    });


                } else if (key.startsWith('SQ')) {
                    let reg = '';
                    if (this.logindeta123 && Array.isArray(this.logindeta123) && this.logindeta123.length > 0) {
                        reg = this.logindeta123[0].RegId;
                    }
                    else {
                        reg = this.logindeta123
                    }
                    this.carin.push({
                        RegId: reg ? reg : localStorage.reg,
                        VehicleTypeId: this.typeID,
                        VehicleBrandId: this.CarmodelID,
                        VehicleNo: this.VehicleNumber,
                        Milage: this.Milage,
                        SOId: value, // Using the value associated with the key for SQ types
                        SSComment: this.SSComment,
                        //Status: true
                    });

                }
            }
        }

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.carin));
        uploadfile.append("param1", JSON.stringify(this.EImage));
        uploadfile.append("param2", JSON.stringify(this.IImage));
        uploadfile.append("param3", JSON.stringify(this.DImage));
        var url = "api/Values/SYC_AddCar_info";
        this.generalService.PostData(url, uploadfile).then(data => {

            if (data == "SUCCESS") {
                this.cardetailsmail();
                this.navgateDelay();              
                //localStorage.clear();
            } else {
                this.generalService.ShowAlert(data, 'Something went wrong. Please try again later.', 'error');
            }
        });
    }
    navgateDelay(): void {
        setTimeout(() => {
            this.router.navigate(['/'])
        }, 2000); // 3000 milliseconds = 3 seconds
    }

    cardetailsmail() {

        this.sendmail = [];
        if (this.logindeta123 && this.logindeta123.length > 0) {
            // If logindeta123 is available and not empty, use its data
            this.sendmail.push({
                FarmerName: this.logindeta123[0].Username || localStorage.Customer,
                Mobile: this.logindeta123[0].Mobile || localStorage.mobile,
                EmailID: this.logindeta123[0].EMail || localStorage.Email,
                BrandName: this.CarModel,
                TypeName: this.Cartypedata,
                Vehiclenumber: this.VehicleNumber,
                Miles: this.Milage,
            });
        } else {
            // If logindeta123 is not available or empty, use other data
            this.sendmail.push({
                FarmerName: this.Username || localStorage.Customer,
                Mobile: this.Mobile || localStorage.mobile,
                EmailID: this.EMail || localStorage.Email,
                BrandName: this.CarModel,
                TypeName: this.Cartypedata,
                Vehiclenumber: this.VehicleNumber,
                Miles: this.Milage,
            });
        }
        var uploadfile = new FormData();
        uploadfile.append("Email", JSON.stringify(this.sendmail));
        var url = "api/SYCar/EnquiryMailTo_SYCCustomerCarDetails";
        this.generalService.PostData(url, uploadfile).then((data: any) => {
            this.MailDetails = data;           
            this.generalService.ShowAlert("SUCCESS", 'Your request has been submitted via email, and our customer support team will be in touch with you shortly.', 'success');
            localStorage.clear();
            window.location.href = '/Vehicleinfo';
        })

    }

    removeImage1(index): void {    /////Remove Image

        this.Exteriorimg.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    //This is for Uploading Multiple Images
    detectFiles(event) {

        //this.urls = [];
        let files = event.target.files;
        if (files && files.length + this.Exteriorimg.length <= this.maxImages) {
            for (let file of files) {
                let reader = new FileReader();
                reader.onload = (e: any) => {

                    this.Exteriorimg.push(e.target.result);
                }
                reader.readAsDataURL(file);
            }
            this.fileChange(event);
        }
        else {
            this.generalService.ShowAlert("warning", 'you can upload only four images', 'warning')
        }
    }
    //This is for Uploading Multiple Images
    fileChange(event) {

        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/SYCar/UploadExteriorImage';
            this.generalService.PostData(url, frmData).then((data: any) => {
                if (data != null) {
                    this.ImageData = data;
                    this.image = data;
                    this.EImage.push({ ExteriorImages: this.ImageData })
                }
            });
        }

    }

    // store images in local storage and upload images
    fileChange5(event) {
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const storedImages = JSON.parse(localStorage.getItem('storedImages')) || [];
            const uploadedImages = [];

            for (let i = 0; i < fileList.length; i++) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    uploadedImages.push(e.target.result);
                };
                reader.readAsDataURL(fileList[i]);
            }

            localStorage.setItem('storedImages', JSON.stringify(storedImages.concat(uploadedImages)));
        }
    }

    removeImage2(index): void {    /////Remove Image

        this.Interiorimg.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }
    //This is for Uploading Multiple Images
    detectFiles1(event) {

        //this.urls = [];
        let files = event.target.files;
        if (files && files.length + this.Interiorimg.length <= this.maxImages) {
            for (let file of files) {
                let reader = new FileReader();
                reader.onload = (e: any) => {
                    this.Interiorimg.push(e.target.result);
                }
                reader.readAsDataURL(file);
            }
            this.fileChange1(event);

        }
        else {
            this.generalService.ShowAlert("warning", 'you can upload only four images', 'warning')
        }


    }
    //This is for Uploading Multiple Images
    fileChange1(event) {

        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/SYCar/UploadInteriorImage';
            this.generalService.PostData(url, frmData).then((data: any) => {
                if (data != null) {

                    this.ImageData1 = data;
                    this.image1 = data;
                    this.IImage.push({ InteriorImages: this.ImageData1 })
                }
            });
        }

    }


    removeImage3(index): void {    /////Remove Image

        this.Documentimg.splice(index, 1);
        this.ImageArray.splice(index, 1);
    }

    //This is for Uploading Multiple Images
    detectFiles2(event) {

        //this.urls = [];
        let files = event.target.files;
        if (files && files.length + this.Documentimg.length <= this.maxImages1) {
            for (let file of files) {
                let reader = new FileReader();
                reader.onload = (e: any) => {
                    this.Documentimg.push(e.target.result);
                }
                reader.readAsDataURL(file);
            }
            this.fileChange2(event);
        }
        else {
            this.generalService.ShowAlert("warning", 'you can upload only two images', 'warning')
        }
    }
    //This is for Uploading Multiple Images
    fileChange2(event) {
        let fileList = event.target.files;

        if (fileList.length > 0) {
            const frmData = new FormData();
            for (var i = 0; i < fileList.length; i++) {
                frmData.append("PostedFile", fileList[i]);
            }
            var url = 'api/SYCar/UploadDocumentImage';
            this.generalService.PostData(url, frmData).then((data: any) => {
                if (data != null) {

                    this.ImageData2 = data;
                    this.image2 = data;
                    this.DImage.push({ DocumentImages: this.ImageData2 })
                }
            });
        }

    }

    carbrand() {
        this.router.navigate(['/CarModels'])
    }

    /*check mobile exist or not*/
    CheckMobileNo() {
        debugger;
        var UploadFile = new FormData();
        var UploadFile = new FormData();
        UploadFile.append("MobileNo", this.Mobile);
        UploadFile.append("Flag", "1");
        var url = "api/SYCar/Get_Check_Mobile_Email"
        debugger;
        this.generalService.PostData(url, UploadFile).then(data => {
            this.mobiledata = data;
            debugger;
            if (data == 'Mobile Exists') {
                this.checkNo = 10;
            }
            else {
                this.checkNo = 11;
            }
        }, err => {
            //this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        });
    }
    /*endregion*/

    Vehiclehistory(): boolean {
        return this.items.some(item => !!item.selected);
    }

    areAllFieldsFilled(): boolean {
     
        return !!this.Username && !!this.Mobile && !!this.EMail && !!this.Password && !!this.Address;
    }

    // vehicle history

    // Function to check if any subquestion is selected
    isSubquestionsSelected(): boolean {
        if (this.selectedAcc !== '46') return true; // If 'No' is selected, subquestions are not required

        // Check if at least one subquestion is selected for the "Yes" option
        return (
            (this.selectedAccident1 !== undefined && this.selectedAccident1 !== null) ||
            (this.selectedpanel !== undefined && this.selectedpanel !== null)
        );
    }


    // vehicle condtion validation start 

    // Function to check if any subquestion is selected
    isSubquestionSelected(): boolean {
        if (this.bpenel !== '53') return true; // If 'No' is selected, subquestion is not required
        for (let item of this.Suboption) {
            if (item.selected) {
                return true;
            }
        }
        return false;
    }

    // Function to check if any subquestion is selected for this question
    isSubquestionDamageSelected(): boolean {
        if (this.sms !== '55') return true; // If 'No' is selected, subquestion is not required
        for (let item of this.Suboptiondamage) {
            if (item.selected) {
                return true;
            }
        }
        return false;
    }

    bodydamagefor57(): boolean {
        if (this.sms1 !== '57') return true;
        for (let item of this.bodydamage) {
            if (item.selected) {
                return true;
            }
        }
        return false;

    }

    // Function to get the Suboption array based on the subquestion index
    getSuboptionArray(index: number): any[] {
        if (index === 8) {
            return this.mcdata;
        } else if (index === 9) {
            return this.mcdata1;
        }
        return [];
    }

    // Function to check if any subquestion is selected for a given index
    mechanicaldata75(index: number): boolean {
        if (this.mcn !== '75') return true; // If 'No' is selected, subquestion is not required
        const subquestion = this.Subquation[index];
        for (let item of this.getSuboptionArray(index)) {
            if (item.selected) {
                return true;
            }
        }
        return false;
    }

    mechanicalcondition77(): boolean {
        if (this.leakpenel !== '77') return true;
        for (let item of this.leakdata) {
            if (item.selected) {
                return true;
            }
        }
        return false;
    }

    mechanicalcondition79(): boolean {
        if (this.leakpenel1 !== '79') return true;
        for (let item of this.leakdata1) {
            if (item.selected) {
                return true;
            }
        }
        return false;
    }

    // Addtionalinformation

    additionalinfo91(): boolean {
        if (this.wel !== '91') return true;
        for (let item of this.wheelsdata) {
            if (item.selected) {
                return true;
            }
        }
        return false;
    }

    addtionalinof93(): boolean {
        if (this.tair1 !== '93') return true;
        for (let item of this.tairsdata) {
            if (item.selected) {
                return true;
            }
        }
        return false;
    }



}


