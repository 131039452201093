<nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/"><b style="color:white;">Home/</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy" style="color:white;">Sell your Car/</b></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy" style="color:white;">Car Brands</b></li>
            <div>Car Brands</div>

        </ul>
    </div>
</nav>
<div class="container">

    <!--<div class="row">
        <div class="col-lg-3 col-md-2 card col-xs-6 mb-3" *ngFor="let item of dataResult">
            <div (click)="getSubCat(item)" id="click" style="cursor:pointer;">
                <img src="{{HomeUrl+item.BrandImage}}" class="card_image col-xs-6" alt="CashYourCar" style="cursor:pointer;margin-left: 64px"> <br />
                <h5 class="card-titlle title"><b>{{item.VehicleBrandName}}</b></h5>
            </div>
        </div>
    </div>-->
    <br />
 
    <div class="row">
        <h3>Let me know your car model</h3>
        <h3></h3>
        <div class="col-lg-3 col-md-2 col-xs-6 mb-3" *ngFor="let item of dataResult">
            <div class="card custom-card" (click)="getSubCat(item)">

                <img src="{{HomeUrl+item.BrandImage}}" class="card-img-top " alt="{{item.VehicleBrandName}}">

                <div class="card-body">
                    <h5 class="card-title">{{item.VehicleBrandName}}</h5>
                </div>
            </div>
        </div>
    </div>
    <div style="border-bottom:1px solid gray;margin-top: 2%;"></div><br/>
    <div class="row">
        <div class="col-lg-3">
            <button type="button" class="back" (click)="carbrand()" >Back</button>
        </div>
        <!--<div class="col-lg-3">
            <button type="button" class="next" (click)="showNextCard1()" [disabled]="!areAllFieldsEntered()">Next</button>
        </div>-->

    </div>

</div>
