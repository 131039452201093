<!--<div class="breadcrumb">
    <div class="conatiner">
        <ul>
            <li class="breadcrumb-item"><a href="">Home</a></li>
            <li class="breadcrumb-item">Login</li>
        </ul>

    </div>
</div>-->

<nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/"><b style="color:white;">Home/</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy" style="color:white;">Login</b></li>
            <div>Login</div>

        </ul>
    </div>
</nav>


<section>
    <div class="conatiner">
        <div class="main-form-container">

            <div id="form_section" class="form-container">

                <div class="login-form form-wraper ">
                    <div>
                        <form [formGroup]="form" (ngSubmit)="OnSubmit()" class="text-left mt-4">

                            <div class="form-title">
                                <h2 style="color:white">Login</h2>
                            </div>
                            <div class="input-group">

                                <div class="form-group">
                                    <input [formControl]="email" class="form-control validation-field" placeholder="Mobile" type="text" maxlength="10" oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 10)">
                                    <!--<small class="text-danger" *ngIf="form.get('EMail')?.touched && form.get('EMail')?.hasError('required')">Email is required</small>
                            <small class="text-danger" *ngIf="form.get('EMail')?.touched && form.get('EMail')?.hasError('email')">Invalid email address</small>-->

                                </div>
                            </div>
                            <div class="input-group">

                                <div class="form-group">
                                    <input [formControl]="Password" class="form-control validation-field" placeholder="Password" type="{{ passwordshow ? 'text' : 'password' }}">
                                    <span class="toggle-password" (click)="togglePasswordVisibility()">
                                        <i class="fa" [ngClass]="{ 'fa-eye': passwordshow, 'fa-eye-slash': !passwordshow }"></i>
                                    </span>
                                    <small class="text-danger" *ngIf="form.get('password')?.touched && form.get('password')?.hasError('required')">Password is required</small>
                                    <small class="text-danger" *ngIf="form.get('password')?.touched && form.get('password')?.hasError('minlength')">Password should be at least 6 characters long</small>

                                </div>
                            </div>
                            <div>
                                <a (click)="ForgotPassword()" class="transition pull-right forgotpas" style="cursor:pointer;margin-top: -40px;float: right;color:white">Forgot password?</a>
                            </div>
                            <div class="form-group">
                                <div class="row">


                                    <div class="col-lg-6 col-xs-6">

                                        <a href="/registration">
                                            <div style="padding:10px; cursor:pointer;color:white">
                                                <b> Sign Up</b>
                                            </div>
                                        </a>

                                    </div>
                                    <div class="col-lg-6 col-xs-6">
                                        <div class="btn1" type="submit" (click)="OnSubmit()">
                                            Login
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="signUp-form form-wraper">
                    <div>
                        <div class="form-title">
                            <h2>Sign Up</h2>
                        </div>

                    </div>
                </div>
            </div>

            <div id="multiple-btn" class="bg-btn-container mobile">
                <h2 style="margin-left: 22px; font-size: 55px;">Cash For Car</h2>
            </div>

        </div>
    </div>
</section>
<div class="modal" id="myModal2" style="margin-top:9rem;">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header state modal-primary">
                <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Mobile</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label>Mobile<span style="color:red">*</span></label>
                    <input [(ngModel)]="Mobile" class="form-control Caps" placeholder="Enter Mobile" type="text" maxlength="10" oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 10)" style="width:450px">
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-default" (click)="CheckMobile(Mobile)">Submit</button>
                <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal" (click)="Close()">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- #endregion -->
<!-- #region check otp popup for forgot password-->
<div class="modal" id="myModal3">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header state modal-primary">
                <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Otp</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label>OTP<span style="color:red">*</span></label>
                    <input [(ngModel)]="OTP" class="form-control Caps" placeholder="Enter Otp" type="text" style="width:450px">
                </div>
                <div class="row" style="float:right;cursor:pointer;margin-right:10px">
                    <a class="resend" (click)="SendOtpForMobile()">Resend OTP</a>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-default" (click)="VerifyOtp(OTP)">Submit</button>
                <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal" (click)="Close()">Close</button>
            </div>
        </div>
    </div>
</div>
<!-- #endregion -->
<!-- #region update customer and vendor password-->
<div class="modal" id="myModal4">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header state modal-primary">
                <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Change Password</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label>Password<span style="color:red">*</span></label>
                    <input [(ngModel)]="Pwd" class="form-control Caps" placeholder="Enter password" type="{{ passwordshow ? 'text' : 'password' }}" style="width:450px">
                    <span class="toggle-password1" (click)="togglePasswordVisibility()">
                        <i class="fa" [ngClass]="{ 'fa-eye': passwordshow, 'fa-eye-slash': !passwordshow }"></i>
                    </span>
                    <br>
                    <label>Confirm Password<span style="color:red">*</span></label>
                    <input [(ngModel)]="Confirmpassword" class="form-control Caps" placeholder="Enter confirm password" type="{{ passwordshow ? 'text' : 'password' }}" style="width:450px">
                    <span class="toggle-password1" (click)="togglePasswordVisibility()">
                        <i class="fa" [ngClass]="{ 'fa-eye': passwordshow, 'fa-eye-slash': !passwordshow }"></i>
                    </span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-default" (click)="ChangePassword(Pwd,Confirmpassword)">Submit</button>
                <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal" (click)="Close()">Close</button>
            </div>
        </div>
    </div>
</div>






<style>
    .container-fluid {
        width: 84%;
    }

    @media only screen and (max-width:600px) {
        .mobile {
            display: none;
        }

        .mobile_logo {
            display: none;
        }

        .bg-btn-container {
            display: flex;
            align-items: center;
            justify-content: space-around;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 190px;
            z-index: 0;
            background: white;
        }

        .form-container {
            padding: 70px 0px;
            background-color: #d0deec;
            border-radius: 10px;
            overflow: hidden;
            position: relative;
            width: 55%;
            transition: 0.3s;
            min-height: 443px;
            z-index: 1;
            display: inline-flex;
            align-items: center;
            margin-left: -4%;
        }

        .form-control {
            height: 45px;
            padding: 10px;
            font-size: 15px;
            background-color: var(--whiteColor);
            border: 1px solid #eeeeee;
            border-radius: 0;
            width: 130%;
            border-radius: 5px;
        }
    }
</style>

