<nav aria-label="breadcrumb"class="breadcrumb">
    <div class="container">
        <ul>

            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/"><b style="color:white;">Home/</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy" style="color:white;">Sell your Car/</b></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy" style="color:white;">Car Types</b></li>
            <div>Car Types</div>

        </ul>
    </div>

</nav>
<div class="container" style="margin-top:20px">

    <div></div><br />
               <div class="row">

                   <!--<div class="col-lg-3 col-md-2  mb-3 col-xs-6" *ngFor="let item of dataResult">

        <div class="card" (click)="getSubCat(item)" id="click" style="cursor:pointer;">
            <img src="{{HomeUrl+item.TypeImage}}" class="card_image col-xs-6" alt="CashYourCar" style="cursor:pointer;"> <br />
            <h5 class="pt-3 card-titlle title"><b  class="card-titlle">{{item.VehicleTypename}}</b></h5>
        </div><br />

    </div>-->

                   <h3>Let me know your car type</h3>
                 <h3></h3>
                   <div class="col-lg-3 col-md-2 col-xs-6 mb-3" *ngFor="let item of dataResult">
                       <div class="card custom-card" (click)="getSubCat(item)" id="click">
                           <br />  <br />
                           <img src="{{HomeUrl+item.TypeImage}}" class="card-img-top " alt="{{item.TypeImage}}">

                           <div class="card-body">
                               <h5 class="card-title">{{item.VehicleTypename}}</h5>
                           </div>
                       </div>
                   </div>





               </div>
</div>
