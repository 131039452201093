import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralService } from '../general.service';
declare var $


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {
    public form: FormGroup;
    arr: any = [];
    isLoggedIn: boolean = false;
    logindata: any;
    logindeta; any;
    Username: any;
    OTP: any;
    Pwd: any;
    Confirmpassword: any;
    Mobile: any;
    randomnumber: any;
    //Password: any;
    public email: AbstractControl;
    public Password: AbstractControl;
    passwordshow: boolean = false;

    togglePasswordVisibility() {
        this.passwordshow = !this.passwordshow;
    }

    constructor(private generalservice: GeneralService, public http: HttpClient, private fb: FormBuilder, private router: Router) {
        this.logindeta = localStorage.getItem('Logindetals');
        this.form = this.fb.group({
            Mobile: ['',],
            Password: ['',],
            email: ['',],
        })
        this.email = this.form.controls['email'];
        this.Password = this.form.controls['Password'];
    }

    ngOnInit() {
        const loginDetails = JSON.parse(localStorage.getItem('LoginDetails') || '{}');
        this.isLoggedIn = loginDetails.isLoggedIn || false;
        this.Username = loginDetails.Username || null;
    }
    logout() {
      
        this.isLoggedIn = false;
        this.Username = null;

        // Clear localStorage
        localStorage.removeItem('LoginDetails');
    }

    Close() {
        $('#myModal2').modal('hide');
        $('#myModal3').modal('hide');
        $('#myModal4').modal('hide');
    }

    public OnSubmit(): void {
        this.arr = [];
        this.arr.push({
            Mobile: this.form.value.email,
            Password: this.form.value.Password,
        });

        if (this.form.valid) {
            var uploadfile = new FormData;
            uploadfile.append("Param", JSON.stringify(this.arr));
            var url = "api/SYCar/SYC_Login";
            this.generalservice.PostData(url, uploadfile).then((data: any) => {
                if (data != null) {
                    if (data == "Invalid User") {
                        this.generalservice.ShowAlert("Warning", 'Please enter valid credentials', 'Warning');
                        this.isLoggedIn = false;
                    } else {
                        localStorage.setItem("LoginDetails", JSON.stringify(data));
                        this.router.navigateByUrl('/navbar').then(() => {
                            this.router.navigate(['/CarTypes']).then(() => {
                                window.location.reload();
                            });
                        });
                    }
                } else {
                    // Handle the case where data is null
                    this.generalservice.ShowAlert("Warning", 'Please enter valid credentials', 'Warning');
                    this.isLoggedIn = false;
                }
            }, (err: any) => {
                this.generalservice.ShowAlert("ERROR", 'Something went wrong. Please try again later.', 'error');
                this.isLoggedIn = false;
            });
        }
    }


    ForgotPassword() {
        this.Mobile = "";
        this.OTP = "";
        this.Pwd = "";
        this.Confirmpassword = "";
        $('#myModal2').modal('show');
    }

    CheckMobile(Mobile) {
        debugger;
        this.arr = [];
        this.arr.push({
            Mobile: Mobile,
            
        })
        if (Mobile == undefined || Mobile == "" || Mobile == null) {
            debugger;
            alert("Please Enter Mobile Number")
            // this.openToast();
        }
        else {
            
            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            //UploadFile.append("Flag", "1");
            var url = "api/SYCar/SYC_Login"
            debugger;
            this.generalservice.PostData(url, UploadFile).then(data => {
                debugger;
                if (data != 'Invalid User') {
                    debugger
                    this.OTP = ""
                    this.Mobile = Mobile;
                    this.SendOtpForMobile();
                    $('#myModal2').modal('hide');
                    $('#myModal3').modal('show');


                }
                else {

                    alert("Please Enter Valid Mobile Number")
                }
            }, err => {
            });
        }

    }

    SendOtpForMobile() {
        debugger

        this.randomnumber = Math.floor(1000 + Math.random() * 9000);
        var msg = "Your Customer OTP is:" + this.randomnumber + " SYCar";
        //var DLTTEID = '1207165215802728246';

        var UploadFile = new FormData();
        UploadFile.append("MobileNo", this.Mobile);
        UploadFile.append("SMS", msg);
        UploadFile.append("OTP", this.randomnumber);
        var url = 'api/SYCar/SendOtpToMobile';
        this.generalservice.PostData(url, UploadFile).then(data => {
            if (data == "SUCCESS") {

            }
            else {

            }
        });
    }
    /*end code*/

    /*verifyotp for forgotpassword*/
    VerifyOtp(otp) {
        debugger
        if (this.randomnumber == otp) {
            $('#myModal3').modal('hide');
            $('#myModal4').modal('show');
        }
        else {
            alert("Invalid OTP");
        }
    }
    /*end region*/

    /*Change password*/
    ChangePassword(Password, confirmpassword) {
        if (Password == undefined || Password == null || Password == "" || confirmpassword == undefined || confirmpassword == null || confirmpassword == "") {
            alert("Please enter password");
        }
        else {
            if (Password != confirmpassword) {
                alert("password and confirmpassword must be same");
            }
            else {
                var UploadFile = new FormData();
                UploadFile.append("Password", Password);
                UploadFile.append("Mobile", this.Mobile);
                var url = "api/SYCar/Forgot_Password"
                debugger;
                this.generalservice.PostData(url, UploadFile).then(data => {
                    debugger;
                    if (data == 'SUCCESS') {
                        this.generalservice.ShowAlert('SUCESS', 'Change Password Successfully.', 'success')
                        $('#myModal4').modal('hide');
                    }
                }, err => {
                    $('#myModal4').modal('hide');
                    this.generalservice.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
                });
            }
        }
    }

    //public onSubmit(): void {
    //    this.arr = [];
    //    this.arr.push({
    //        EMail: this.form.value.EMail,
    //        password: this.form.value.password,
    //    });
    //    debugger
    //    if (this.form.valid) {
    //        var formData = new FormData();
    //        formData.append("Param", JSON.stringify(this.arr));
    //        var url = "api/SYCar/SYC_Login";
    //        this.generalService.PostData(url, formData)
    //            .then((data: any) => {
    //                debugger
    //                if (data != null) {
    //                    if (data == "Invalid User") {
    //                        //   this.logindata = data;
    //                        this.generalService.ShowAlert("Warning", 'Please Enter Valid Credentials', 'Warning');
    //                    } else {
    //                        localStorage.setItem("LoginDetails", JSON.stringify(data));
    //                        this.router.navigate(['/dashboard']);
    //                    }
    //                }
    //            })
    //            .catch((error: any) => {
    //                console.error("Error occurred:", error);
    //                // Handle error appropriately, show error message to the user
    //                this.generalService.ShowAlert("ERROR", 'Something went wrong. Please try again later.', 'error');
    //            });
    //    }
    //}
}

