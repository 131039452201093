import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../general.service';


import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';


import { Router } from '@angular/router';
@Component({
  selector: 'app-car-types',
  templateUrl: './car-types.component.html',
  styleUrls: ['./car-types.component.scss']
})
export class CarTypesComponent {
    HomeUrl: any;
    arr: any;
    dataResult: any;
    Cartypedata: any;

    constructor(public router: Router, public generalService: GeneralService, public http: HttpClient) {
        debugger
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
            
        });
    }

    ngOnInit(): void {
        this.GetCarTypes();

    }
    GetCarTypes() {
        debugger
        this.arr = []
        this.arr.push({ RegId: '2' ,
            TokenId:'3A55AF42-D929-4699-A033-644CE99CCAAA'});
        var UploadFile = new FormData();
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/SYCar/SYC_VehicleType_Curd";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            this.dataResult = data
        }, err => {
        })
    }

    getSubCat(data: any) {

        debugger
        localStorage.setItem("Cartype", data.VehicleTypename);
        localStorage.setItem("CartypeId", data.VehicleTypeID);
        this.router.navigate(['/CarModels'])
        
    }
}
