<nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/"><b style="color:white;">Home/</b></a></li>
            <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy" style="color:white;">Registration</b></li>
            <div>Registration</div>

        </ul>
    </div>
</nav>
<div class="fluid">
    <div class="container">
        <div class="modernForm">
            <div class="imageSection">
                <div class="image">
                    <div class="overlay">  </div>
                    <h2 class="cash_car">Cash For Car</h2>
                    <!--<img src="../../assets/img/Sellyoucarimgs/bgremove_logo.png" alt="cars" class="logo" />-->
                </div>

            </div>
            <div class="contactForm">
                <h1></h1>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-group name">
                        <div class="iconName"><i class="fa-solid fa-user"></i></div>
                        <input type="text" formControlName="CustmoerName" name="fullName" id="fullName" placeholder="Enter Full Name" required />
                    </div>
                    <div class="form-group name">
                        <input type="text" formControlName="Mobile" name="mobile" placeholder="Mobile Number" (input)="CheckMobileNo()" maxlength="10" required />
                        <div class="iconName">
                            <i class="fa-solid fa-phone"></i>
                        </div>
                    </div>
                    <small class="text-danger" *ngIf="form.get('Mobile').touched">
                        <span *ngIf="form.get('Mobile').hasError('required')">Mobile is required</span>
                        <span *ngIf="checkNo==10">Mobile Number Exist</span>
                    </small>

                    <div class="form-group name">
                        <input type="email" formControlName="Email" name="email" id="email" placeholder="Email" required />
                        <div class="iconName"><i class="fa-solid fa-envelope"></i></div>
                    </div>
                    <div class="form-group name">
                        <input type="{{ passwordshow ? 'text' : 'password' }}" formControlName="Password" name="password" id="password" placeholder="Password" required />
                        <span class="toggle-password" (click)="togglePasswordVisibility()">
                            <i class="fa" [ngClass]="{ 'fa-eye': passwordshow, 'fa-eye-slash': !passwordshow }"></i>
                        </span>
                        <div class="iconName"><i class="fa-solid fa-lock"></i></div>
                        <small class="text-danger" *ngIf="form.get('Password').touched">
                            <span *ngIf="form.get('Password').hasError('required')">Password is required</span>
                            <span *ngIf="form.get('Password').hasError('minlength')">Password isn't long enough, minimum of 6 characters</span>
                        </small>
                    </div>

                    <div class="form-group name">
                        <textarea rows="4" formControlName="Address" cols="70" placeholder="Enter Your Address" class="text"></textarea>
                        <small class="text-danger" *ngIf="form.get('Address').touched">
                            <span *ngIf="form.get('Address').hasError('required')">Address is required</span>
                            <span *ngIf="form.get('Address').hasError('minlength')">Minimum of 6 characters</span>
                        </small>
                    </div>

                    <div class="form-group name">
                        <input type="submit" value="Register" [disabled]="form.invalid">
                    </div>

                </form>
                <small class="text-danger form-group name" *ngIf="form.invalid">
                    <b>Please Fill Above all details above.</b>
                </small>
            </div>
        </div>
    </div>
</div>

<style>
    .container-fluid {
        width: 84%;
    }
</style>
