<div class="strategy-area">
    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-12">

                    <!--<img src="assets/img/about-strategy.jpg" alt="image">-->
                    <img src="../../../../assets/img/Sellyoucarimgs/sellurcar.JPG" />
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="strategy-content ptb-70">
                        <div class="section-title">


                            <h2> Why Wait? <span>Get Started Today!</span></h2>
                            <p>
                                Ready to turn your car into cash? Start the process now by filling out our online form. We're committed to making your selling experience stress-free and rewarding.
                            </p>
                        </div>
                        <!--<ul class="features-list">
                    <li><i class="fa fa-check"></i>Creative Design</li>
                    <li><i class="fa fa-check"></i>Retina Ready</li>
                    <li><i class="fa fa-check"></i>Responsive Design</li>
                    <li><i class="fa fa-check"></i>Modern Design</li>
                    <li><i class="fa fa-check"></i>Awesome Design</li>
                    <li><i class="fa fa-check"></i>Digital Marketing & Branding</li>
                </ul>-->
                        <p>
                            Join the countless satisfied customers who have chosen Cars For Cash as their trusted partner in selling their cars. Contact us today and experience a new level of convenience and value!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--<div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>-->
</div>
