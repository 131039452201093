<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <!--<h4>We are creative</h4>-->
            <h2>Welcome to <span>Cash For Car</span></h2>
            <p> Your Trusted Partner in Selling Your Car! </p>
        </div>
        <div class="row justify-content-center">
          
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Cash For Car</h3>
                    <p></p> <a href="CarTypes" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                    <p>Are you ready to upgrade your ride or simply looking to part ways with your current vehicle? </p>
                </div>
            </div>
            <div class="col-lg-8 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>Cash For Car with Ease and Confidence </h3>
                    <p></p> <a href="CarTypes" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                    <p> Look no further! <span>At Cash For Car</span>, we understand that selling your car can be a hassle, but we're here to make the process smooth, transparent, and rewarding for you.</p><br />
                </div>
                <!--<div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>Cars For Cash with Ease and Confidence </h3>
                    <p> Look no further! <span>At Cars For Cash</span>, we understand that selling your car can be a hassle, but we're here to make the process smooth, transparent, and rewarding for you.</p><br />
                    <a href="CarTypes" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>-->
            </div>
            <!--<div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>Friendly Codes</h3>
                    <p>Lorem ipsum dolor sit amet sit ipsum, consectet adipiscing elit consectetur, ipsum dolor sed do eiusmod tempor.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>-->
            <!--<div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-box">
            <div class="icon">
                <i class="far fa-life-ring"></i>
            </div>
            <h3>Fast Support</h3>
            <p>Lorem ipsum dolor sit amet sit ipsum, consectet adipiscing elit consectetur, ipsum dolor sed do eiusmod tempor.</p>
            <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
        </div>
    </div>-->
        </div>
    </div>
    <!--<div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>-->
</div>
